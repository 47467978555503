import React from 'react';
import axios from 'axios';

export default class extends React.Component {
    componentDidMount() {
        axios.get('/api/video-infos/' + this.props.appContext.state.videoId).then(({ data }) => {
            if ('error' in data) {
                this.props.appContext.setState({error: data.error});
            } else {
                if (data.length === 0) {
                    this.props.appContext.setState({error: "This video doesn't exist"});
                }
                this.props.appContext.setState({videoInfos: data[0]});
            }
        });
    }

    goToVideosList = () => {
        window.history.pushState({}, "ASMR triggers", "/");
        this.props.appContext.setState({videoId: 0});
    };

    render() {
        let videoInfos = this.props.appContext.state.videoInfos;
        return (
            <div>
                { !videoInfos ? (
                    <div className="text-center">getting video infos...</div>
                ):(
                    <div>
                        <div className="text-center">
                            <h1>
                                <button className="btn btn-primary mr-3" onClick={ this.goToVideosList }>Back</button>
                                Video Trigger Croper
                            </h1>
                            <br/>
                            <video controls="controls" ref={this.props.appContext.videoRef} className="w-100" src={videoInfos['server-url']}/>
                            {/*<iframe width="560" height="315"
                                    src={ 'https://www.youtube.com/embed/' + videoInfos.url.split('watch?v=')[1] }
                                    frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>*/}
                        </div>
                        <br/>

                        <div className="row">
                            <form className="col">
                                <div className="form-group row mb-1">
                                    <label htmlFor="url" className="col-sm-3 col-form-label">YouTube URL</label>
                                    <div className="col-sm-9">
                                        <a className="d-block mt-2"
                                           href={ videoInfos.url }>{ videoInfos.url }</a>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <label htmlFor="url" className="col-sm-3 col-form-label">Server URL</label>
                                    <div className="col-sm-9">
                                        <a className="d-block mt-2"
                                           href={ videoInfos['server-url'] }>{ videoInfos['server-url'] }</a>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <label htmlFor="title" className="col-sm-3 col-form-label">Title</label>
                                    <div className="col-sm-9">
                                        <input type="text" readOnly className="form-control" id="title"
                                               value={ videoInfos.title }/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <br/>
                    </div>
                )}
            </div>
        )
    }
};
