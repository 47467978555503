import React from 'react';
import axios from 'axios';

export default class extends React.Component {
    state = {
        startTime: 0,
        endTime: 0,
        categoryId: null,
        categoriesList: [],
    };

    componentDidMount() {
        axios.get('/api/trigger-categories').then(({ data }) => {
            if ('error' in data) {
                this.props.appContext.setState({error: data.error});
            } else {
                this.setState({categoriesList: data, categoryId: 1});
            }
        });
    }

    addNewTrigger = () => {
        let { startTime, endTime, categoryId } = this.state;
        if (!this.isNumber(startTime) || (!this.isNumber(endTime)|| (!this.isNumber(categoryId)))) {
            alert('error. please verify your fields');
            return;
        }
        if (startTime >= endTime) {
            alert('Start time must be smaller than end time');
            return;
        }
        axios.post('/api/new-trigger/' + this.props.appContext.state.videoId, {
            startTime: startTime, endTime: endTime, categoryId: categoryId
        }).then(res => {
            this.props.appContext.refreshTriggersList(this.props.appContext.state.videoId);
        })
    };

    isNumber(element) {
        return /^[0-9\.]+$/.test(element);
    };

    refreshTime = button => () => {
        let newState = {};
        newState[button] = Math.round(this.props.appContext.videoRef.current.currentTime * 100) / 100;
        this.setState(newState);
    };

    render() {
        return (
            <div>
                <h1 className="text-center">New Trigger</h1>
                <br/>
                <div className="form-group row">
                    <label htmlFor="url" className="col-sm-2 col-form-label">Category</label>
                    <div className="col-sm-10">
                        { this.state.categoriesList.length > 0 ? (
                            <select className="form-control">
                                {this.state.categoriesList.map(category => (
                                    <option value={category.id}
                                            selected={category.id === this.state.categoryId}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        ):null }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-2"/>
                    <div className="col">
                        <div className="row">
                            <label className="col-auto col-form-label text-center">Start (s)</label>
                            <div className="col pr-1">
                                <input value={this.state.startTime} type="text"
                                       onChange={event => this.setState({ startTime: event.target.value})}
                                       className={'form-control' + (this.isNumber(this.state.startTime) ? '':' is-invalid')}/>
                            </div>
                            <div className="col-auto pl-1">
                                <button className="btn btn-light" onClick={this.refreshTime('startTime')}>
                                    &#x21bb;
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="row">
                            <label className="col-auto col-form-label text-center">End (s)</label>
                            <div className="col pr-1">
                                <input value={this.state.endTime} type="text"
                                       onChange={event => this.setState({ endTime: event.target.value})}
                                       className={'form-control' + (this.isNumber(this.state.endTime) ? '':' is-invalid')}/>
                            </div>
                            <div className="col-auto pl-1">
                                <button className="btn btn-light" onClick={this.refreshTime('endTime')}>
                                    &#x21bb;
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-primary" onClick={this.addNewTrigger}>Add trigger</button>
                    </div>
                </div>
            </div>
        )
    }
};
