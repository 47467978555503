import React from 'react';
import axios from "axios";

export default class extends React.Component {
    deleteTrigger = triggerId => () => {
        axios.get('/api/delete-trigger/' + triggerId).then(res => {
           this.props.appContext.refreshTriggersList(this.props.appContext.state.videoId);
        });
    };

    render() {
        return (
            <div>
                <h1 className="text-center mt-5">All Triggers</h1>
                <div className="row">
                    { this.props.appContext.state.triggersList.length === 0 ? (
                        <div className="col">
                            <div className="text-center">No trigger registered for this video</div>
                        </div>
                    ):(
                        <table className="table table-striped mt-3">
                            <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Start (s)</th>
                                <th scope="col">End (s)</th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            { this.props.appContext.state.triggersList.map((trigger) => (
                                <tr>
                                    <td>{ trigger.category }</td>
                                    <td>{ trigger['start-time'] }</td>
                                    <td>{ trigger['end-time'] }</td>
                                    <td className="float-right">
                                        <button className="btn btn-primary">Go</button>
                                        <button className="btn btn-danger ml-3"
                                                onClick={this.deleteTrigger(trigger.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            )) }
                            </tbody>
                        </table>
                    ) }
                </div>
            </div>
        )
    };
}
