import React from 'react';
import axios from "axios";

export default class extends React.Component {
    state = {
        searchValue: '',
        originalVideoList: [],
        filteredVideoList: []
    };

    goToVideo = (videoId) => () => {
        window.history.pushState({}, "ASMR triggers", "/video/" + videoId + '/');
        this.props.appContext.setState({ videoId: videoId, videoInfos: false });
        this.props.appContext.refreshTriggersList(videoId);
    };

    filterVideoList = (withSearchValue=false) => {
        let searchValue = !withSearchValue ? this.state.searchValue:withSearchValue;
        return this.state.originalVideoList.filter(video => video.title.toLowerCase().includes(searchValue));
    };

    searchVideos = (event) => {
        let newList = Array.from(this.filterVideoList(event.target.value));
        this.setState({ searchValue: event.target.value, filteredVideoList: newList });
    };

    componentDidMount() {
        axios.get('/api/videos').then(({ data }) => {
            if ('error' in data) {
                this.props.appContext.setState({error: data.error});
            } else {
                this.setState({originalVideoList: data, filteredVideoList: data});
            }
        });
    }

    render() {
        return (
            <div>
                <h1 className="text-center mb-4">
                    Video Trigger Croper
                    <a href="https://ytdl.pyme.ch?saveInDB=true" target="_blank" className="btn btn-dark ml-3" >Download more videos</a>
                </h1>
                <div className="row">
                    <div className="col">
                        <input className="form-control" type="text" placeholder="Search..." value={this.state.searchValue} onChange={this.searchVideos}/>
                        <br/>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Video title</th>
                                <th className="text-center">Video on Youtube</th>
                                <th className="text-center">Video details</th>
                            </tr>
                            </thead>
                            <tbody>
                            { this.state.filteredVideoList.map((video) => (
                                <tr>
                                    <td className="align-middle">{video.title}</td>
                                    <td className="text-center">
                                        <a className="text-white btn btn-danger" target="_blank" href={video.url}>Go</a>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-primary" onClick={this.goToVideo(video.id)}>Go</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

