import React from 'react';
import VideoHeader from './components/VideoHeader';
import NewTrigger from "./components/NewTrigger";
import TriggersList from "./components/TriggersList";
import VideoList from "./components/VideoList";
import './App.css';
import axios from "axios";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.firstLoad = true;
        this.urlMatch = window.location.href.split('/video/');
        this.state = {
            videoId: this.urlMatch.length > 1 ? this.urlMatch[1].replace('/', '') : 0,
            triggersList: [],
            error: false,
            videoInfos: false,
        };
        this.videoRef = React.createRef();
    }

    refreshTriggersList(videoId) {
        axios.get('/api/video-triggers/' + videoId).then(({ data }) => {
            if ('error' in data) {
                this.setState({error: data.error});
            } else {
                this.setState({triggersList: data});
            }
        });
    }

    componentDidMount() {
        if (this.urlMatch.length > 1 && this.firstLoad) {
            this.refreshTriggersList(this.state.videoId);
        }
        this.firstLoad = false;
    }

    render() {
        return (
            <div className="container mb-4 mt-4">
                { this.state.error ? (
                    <div className="alert alert-danger text-center mt-4">{ this.state.error }</div>
                ): this.state.videoId === 0 ? (
                    <div>
                        <VideoList appContext={this}/>
                    </div>
                ):( this.state.videoInfos ? (
                    <div>
                        <VideoHeader appContext={this}/>
                        <NewTrigger appContext={this}/>
                        <TriggersList appContext={this}/>
                    </div>
                ): <VideoHeader appContext={this}/> )}
            </div>
        );
    }
}
